import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import ExperienceItem from "./ExperienceItem";
import { rhythm } from "../utils/typography";
import moment from "moment";

const StyledExp = styled.section`
  grid-area: experience;
`;

const StyledExpList = styled.div`
  display: grid;
  gap: ${rhythm(1)};
`;

const Experience = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityWorkexperience {
        nodes {
          _id
          title
          company
          dates {
            enddate
            startdate
          }
          body: _rawBody(resolveReferences: { maxDepth: 5 })
          logo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  const mappedNodes = data.allSanityWorkexperience.nodes.map(n => {
    return {
      ...n,
      dates: n.dates.map(x => ({
        startdate: moment(x.startdate),
        enddate: moment(x.enddate),
      })),
    };
  });

  const experiences = mappedNodes.sort((a, b) => {
    const aDate = a.dates[a.dates.length - 1].enddate;
    const bDate = b.dates[b.dates.length - 1].enddate;

    if (aDate.isValid() === false) return -1;
    if (bDate.isValid() === false) return 1;

    return aDate.isBefore(bDate) ? 1 : -1;
  });

  return (
    <StyledExp>
      <h1>Experience</h1>
      <StyledExpList>
        {experiences.map(x => (
          <ExperienceItem {...x} key={x._id} />
        ))}
      </StyledExpList>
    </StyledExp>
  );
};

export default Experience;
