import React from "react";
import styled from "styled-components";
import moment from "moment";
import { rhythm } from "../utils/typography";
import DateRange from "./DateRange";

export const StyledDateList = styled.ul`
  list-style: none;
  line-height: ${rhythm(0.8)};
  margin: 0;

  li {
    display: inline-block;
    margin-right: ${rhythm(0.3)};
    margin-bottom: 0;
  }
  li:not(:last-child):after {
    content: ", ";
  }
`;

export const DateList = ({ dates, format, showDashOnIncomplete }) => {
  const formattedDates = dates.map(x => {
    const startMoment = moment(x.startdate);
    const endMoment = moment(x.enddate);

    return (
      <DateRange
        as="li"
        key={x.startdate}
        start={startMoment.isValid() ? startMoment.format(format) : null}
        end={endMoment.isValid() ? endMoment.format(format) : null}
        inline
        showDashOnIncomplete={showDashOnIncomplete}
      />
    );
  });

  return <StyledDateList>{formattedDates}</StyledDateList>;
};
