import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-feather/mail";
import phoneIcon from "@iconify/icons-feather/phone";
import { rhythm } from "../utils/typography";

const StyledAboutMe = styled.section`
  grid-area: about;
  display: grid;
  grid-template-columns: ${rhythm(3.6)} auto;
  grid-template-areas:
    "img greet"
    "info info";
  grid-row-gap: ${rhythm(2)};
  margin-bottom: ${rhythm(1)};
`;

const ProfileGreetingWrapper = styled.div`
  grid-area: greet;
  color: ${props => props.theme.secondary};

  > * {
    margin: 0;
  }
`;

const ProfileImg = styled(Image)`
  grid-area: img;

  min-width: ${rhythm(3)};
  min-height: ${rhythm(3)};
  max-width: ${rhythm(3)};
  max-height: ${rhythm(3)};
  border-radius: 50%;
`;

const ProfileInfo = styled.div`
  grid-area: info;
  min-width: 100%;
`;

const AboutMe = () => {
  const data = useStaticQuery(graphql`
    {
      sanityPageInfo {
        greeting
        person
        title
        email
        phone
        info: _rawInfo(resolveReferences: { maxDepth: 5 })
        profileImg {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  const {
    person,
    greeting,
    email,
    phone,
    info,
    profileImg,
  } = data.sanityPageInfo;

  return (
    <StyledAboutMe>
      <ProfileImg fluid={profileImg.asset.fluid} />
      <ProfileGreetingWrapper>
        <h1>{person}</h1>
        {greeting}
      </ProfileGreetingWrapper>
      <ProfileInfo>
        <BlockContent blocks={info} />
        <div>Let's talk.</div>
        <div>
          <a href={`mailto:${email}`}>
            <InlineIcon icon={emailIcon} /> {email}
          </a>
        </div>
        <div>
          <a href={`tel:${phone}`}>
            <InlineIcon icon={phoneIcon} /> {phone}
          </a>
        </div>
      </ProfileInfo>
    </StyledAboutMe>
  );
};

export default AboutMe;
