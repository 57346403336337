import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import ExpertiseItem from "./ExpertiseItem";
import { rhythm } from "../utils/typography";

const StyledExpertise = styled.section`
  grid-area: expertise;
`;

const StyledList = styled.ul`
  list-style: none;
  margin-left: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 ${rhythm(0.5)};
`;

const Expertise = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityCategory {
        nodes {
          _id
          title
          icon {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledExpertise>
      <h1>Expertise</h1>
      <StyledList>
        {data.allSanityCategory.nodes.map(x => (
          <ExpertiseItem img={x.icon.asset} title={x.title} key={x._id} />
        ))}
      </StyledList>
    </StyledExpertise>
  );
};

export default Expertise;
