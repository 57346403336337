import React from "react";
import Image from "gatsby-image";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import { InlineIcon } from "@iconify/react";
import externalLink from "@iconify/icons-feather/external-link";

import { rhythm } from "../utils/typography";
import media from "styled-media-query";
import { DateList } from "./DateList";

const StyledItem = styled.div`
  display: grid;
  grid-template-areas:
    "image text"
    "image text"
    "date text"
    ". text";
  gap: 0 ${rhythm(1)};
  grid-template-columns: 0.8fr 1.2fr;
  grid-template-rows: auto auto auto 1fr;
  grid-auto-flow: row;

  ${media.lessThan("medium")`
      grid-template-areas:
        "date"
		"image"
        "text";
      grid-template-columns: 1fr;
  `}
`;

const StyledItemImg = styled(Image)`
  grid-area: image;
`;

const StyledItemText = styled.div`
  grid-area: text;
`;

const StyledItemDate = styled(DateList)`
  grid-area: date;
`;

const StyledItemTitle = styled.h2`
  grid-area: title;
  margin: 0;
  display: inline-block;

  a {
    color: ${props => props.theme.primary};
    :hover {
      color: ${props => props.theme.secondary};
    }
  }

  svg {
    color: ${props => props.theme.secondary};
  }

  span {
    margin-right: ${rhythm(0.5)};
  }
`;

const PortfolioItem = ({ title, body, link, mainImage, dates }) => {
  return (
    <StyledItem>
      {mainImage && (
        <a
          href={link}
          alt={`More about ${title}`}
          target="_blank"
          rel="noopener"
        >
          <StyledItemImg fluid={mainImage.asset.fluid} />
        </a>
      )}

      <StyledItemDate dates={dates} format="YYYY" />
      <StyledItemText>
        <StyledItemTitle>
          <a
            href={link}
            alt={`More about ${title}`}
            target="_blank"
            rel="noopener"
          >
            <span>{title}</span>
            <InlineIcon icon={externalLink} />
          </a>
        </StyledItemTitle>
        <BlockContent blocks={body} />
      </StyledItemText>
    </StyledItem>
  );
};

export default PortfolioItem;
