import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rhythm } from "../utils/typography";

const Container = styled.div`
  width: ${props => rhythm(props.imgSize)};
  height: ${props => rhythm(props.imgSize)};
  background: ${props => (props.theme.isDark ? "white" : null)};
  border-radius: 50%;
  padding: ${props => rhythm(props.imgPadding)};
  display: flex;
  align-items: center;
  margin-right: ${rhythm(0.5)};

  > * {
    min-width: 100%;
    min-height: 100%;
  }
`;

const CircleImgContainer = ({ children, imgSize, imgPadding }) => {
  return (
    <Container imgSize={imgSize} imgPadding={imgPadding}>
      {children}
    </Container>
  );
};

export default CircleImgContainer;

CircleImgContainer.propTypes = {
  children: PropTypes.node.isRequired,
  imgSize: PropTypes.number,
  imgPadding: PropTypes.number,
};

CircleImgContainer.defaultProps = {
  imgSize: 2,
  imgPadding: 0.1,
};
