import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import EducationItem from "./EducationItem";
import { rhythm } from "../utils/typography";

const StyledEducation = styled.section`
  grid-area: edu;
  display: grid;
  row-gap: ${rhythm(0.5)};
`;

const Education = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityEducation {
        nodes {
          _id
          school
          dates {
            startdate
            enddate
          }
          title
          major
          logo {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <StyledEducation>
      <h1>Education</h1>
      {data.allSanityEducation.nodes.map(x => (
        <EducationItem data={x} key={x._id} />
      ))}
    </StyledEducation>
  );
};

export default Education;
