import React from "react";
import Image from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import CircleImgContainer from "./CircleImgContainer";

const ExpertiseIcon = styled(Image)`
  margin-right: ${rhythm(0.5)};
`;

const StyledExpItem = styled.li`
  display: flex;
  flex-direction: row;
`;

const ExpertiseItem = ({ img, title }) => {
  return (
    <StyledExpItem>
      <CircleImgContainer imgSize={1} imgPadding={0.2}>
        <ExpertiseIcon fluid={img.fluid} objectFit="contain" />
      </CircleImgContainer>
      <p>{title}</p>
    </StyledExpItem>
  );
};

export default ExpertiseItem;
