import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutMe from "../components/AboutMe";
import Expertise from "../components/Expertise";
import Experience from "../components/Experience";
import Portfolio from "../components/Portfolio";
import Education from "../components/Education";
import { rhythm } from "../utils/typography";

const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  gap: ${rhythm(1)} ${rhythm(3)};

  grid-template-areas:
    "about p"
    "expertise p"
    "experience p"
    "edu p"
    ". p";

  ${media.lessThan("medium")`
  grid-template-columns: auto;
        grid-template-areas:
    "about"
    "expertise"
    "experience"
    "edu"
    "p";
    `}
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <IndexGrid>
      <AboutMe />
      <Expertise />
      <Experience />
      <Education />
      <Portfolio />
    </IndexGrid>
  </Layout>
);

export default IndexPage;
