import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledRange = styled.div`
  display: ${props => (props.inline ? "inline-block" : null)};
  color: ${props => props.theme.secondary};
`;

const DateRange = ({ start, end, inline, showDashOnIncomplete, ...props }) => {
  const startValid = !!start;
  const endValid = !!end;

  if (!startValid && !endValid) return null;

  return (
    <StyledRange inline={inline} {...props}>
      {startValid && start}
      {(startValid && endValid) || showDashOnIncomplete ? "—" : null}
      {endValid && end}
    </StyledRange>
  );
};

export default DateRange;

DateRange.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  showDashOnIncomplete: PropTypes.bool.isRequired,
};

DateRange.defaultProps = {
  inline: false,
  showDashOnIncomplete: false,
};
