import React from "react";
import Image from "gatsby-image/withIEPolyfill";
import styled from "styled-components";

import { rhythm } from "../utils/typography";
import CircleImgContainer from "./CircleImgContainer";
import { DateList } from "./DateList";

const StyledEducationItem = styled.article`
  display: grid;
  grid-template-areas: "logo text";
  grid-template-columns: 0.2fr 1.8fr;
  grid-template-rows: ${rhythm(4)};
  gap: ${rhythm(0.6)};
`;

const EduLogo = styled(Image)`
  grid-area: logo;
`;

const EduText = styled.div`
  grid-area: text;

  > * {
    margin: 0;
  }
`;

const EducationItem = ({ data }) => {
  const { dates, title, major, logo } = data;

  return (
    <StyledEducationItem>
      <CircleImgContainer>
        <EduLogo
          fluid={logo.asset.fluid}
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </CircleImgContainer>
      <EduText>
        <h2>{title}</h2>
        <h4>Major: {major}</h4>
        <DateList dates={[dates]} format="YYYY" />
      </EduText>
    </StyledEducationItem>
  );
};

export default EducationItem;
