import React from "react";
import Image from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import { rhythm } from "../utils/typography";
import CircleImgContainer from "./CircleImgContainer";
import { DateList } from "./DateList";

const StyledExpItem = styled.li`
  display: grid;
  grid-template-columns: 0.2fr 1.8fr;
  gap: ${rhythm(0.2)} ${rhythm(1)};
`;

const ExpText = styled.div`
  > * {
    margin: 0;
  }
`;

const ExperienceItem = ({ logo, title, company, dates, body }) => {
  return (
    <StyledExpItem>
      <CircleImgContainer imgPadding={0.4}>
        <Image fluid={logo.asset.fluid} objectFit="contain" />
      </CircleImgContainer>
      <ExpText>
        <h2>
          {title} @ {company}
        </h2>
        <DateList
          dates={dates}
          format="MM/YYYY"
          showDashOnIncomplete
        ></DateList>
        <BlockContent blocks={body} />
      </ExpText>
    </StyledExpItem>
  );
};

export default ExperienceItem;
