import React from "react";
import styled from "styled-components";

import PortfolioItem from "./PortfolioItem";
import { useStaticQuery, graphql } from "gatsby";
import { rhythm } from "../utils/typography";

const StyledPortfolio = styled.section`
  grid-area: p;
`;

const PortfolioContent = styled.div`
  display: grid;
  gap: ${rhythm(1.5)};
`;

const PortfolioTitle = styled.h1``;

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProject(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          _id
          title
          body: _rawBody(resolveReferences: { maxDepth: 5 })
          link
          startdate: publishedAt
          categories {
            title
            icon {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          mainImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledPortfolio>
      <PortfolioTitle>Portfolio</PortfolioTitle>
      <PortfolioContent>
        {data.allSanityProject.nodes.map(x => (
          <PortfolioItem
            {...x}
            dates={[{ startdate: x.startdate, enddate: null }]}
            key={x._id}
          ></PortfolioItem>
        ))}
      </PortfolioContent>
    </StyledPortfolio>
  );
};

export default Portfolio;
